import React from 'react'
import "./Section2.css"
import afroMojo from "./AFRO-MOJO.png"
import KODAI from "./KODAI.png"
import PERL from "./PERL.png"
import GANESH from "./GANESH.png"
import logo from "./logo2.png"
import {Box,Grid} from '@mui/material';


function Section2() {
    return (
        <div style={{padding:'3rem',color:'white',marginTop:'-5rem'}}>
   <Box  sx={{ flexGrow: 1 }}>
      <Grid container  alignItems="center" spacing={1}>
        <Grid  item xs={12} md={6}>
                <Grid container  alignItems="center" spacing={1}>
                <Grid  item xs={6} md={6}>
                <img width="85%" height="80%" src={afroMojo} alt=""/>
                </Grid>
                <Grid  item xs={6} md={6}>
                <img width="81%" height="74%" src={KODAI} alt=""/>
                </Grid>
                <Grid  item xs={6} md={6}>
                <img width="85%" height="80%" src={PERL} alt=""/>
                </Grid>
                <Grid  item xs={6} md={6}>
                <img width="85%" height="80%" src={GANESH} alt=""/>
                </Grid>
                </Grid>
        </Grid>
        <Grid style={{textAlign:'left',padding:'2rem'}}  item xs={12} md={6}>
            <h1>Welcome to</h1>
     
            <img className="glow section2__logo"  src={logo} alt="" />
            <p>
            Join the epic battle between Cypherpunks and K-otic Corp

            - a collection of 47,500 unique digital collectibles and
            limited edition physical SNKR’s - powered by

            the Solana Blockchain, Dropping on November 12th 2021.
            </p>
            <p>
            We have created 38 cards in 6 variations, 19 characters
            and 19 SNKRs plus a few surprise mystics. We will release
            9,500 packs of 5 cards each with a bonus card in each pack.
            </p>
            <p>
            Starting with hand drawn characters and SNKRs we bring
            you through the mystical journey of 2D and 3D animation
            to eventually find out if you are one of 150 claimable SNKRs
            <br></br>
            <p style={{color:'white'}}>The epic hunt for a GOAT begins now!</p>
            </p>
        
        </Grid>
        </Grid>
    </Box>
    <hr></hr>
        </div>
    )
}

export default Section2
