import { createSlice } from '@reduxjs/toolkit';


const initialState={
 crate:false,
  


}

export const crateSlice = createSlice({
  name: 'crate',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    fetchCrate: (state,action) => {
      state.crate = action.payload
      
       
    },
    // logout: (state,action) => {
    //   state.loading=null
    // },

  
  },

});

export const { fetchCrate } = crateSlice.actions;
export const selectcrate = (state) => state.crate;
export default crateSlice.reducer;