import './App.css';
import { Suspense,lazy } from 'react';
// import Appbar from "./components/Appbar/Appbar"
// import Section1 from './components/Section1/Section1';
import Section2 from './components/Section2/Section2';
// import Section3 from './components/Section3/Section3';
// import Section4 from './components/Section4/Section4';
// import Section5 from './components/Section5/Section5';
import { BrowserRouter, Route, Switch } from "react-router-dom"
// import Minting from './components/Minting/Minting';
// import Main from './components/Main';
// import Section6 from './components/Section6/Section6';
// import Success from './components/Success/Success';

const Appbar=lazy(()=> import("./components/Appbar/Appbar"))

const Section1=lazy(()=> import("./components/Section1/Section1"))

const Section3=lazy(()=> import("./components/Section3/Section3"))

const Section4=lazy(()=> import("./components/Section4/Section4"))

const Section5=lazy(()=> import("./components/Section5/Section5"))

const Section6=lazy(()=> import("./components/Section6/Section6"))

const Minting=lazy(()=> import("./components/Minting/Minting"))

const Success=lazy(()=> import("./components/Success/Success"))

const Main=lazy(()=> import("./components/Main"))
function App() {

  return (
    <div className="App">
  <BrowserRouter>
  <Switch>
    <Route exact path="/">
      <Suspense fallback={<div/>}>
      <Appbar/>
      </Suspense>

      
          {/* <Suspense fallback={<div/>}>
          <Section1/>
         </Suspense> */}
          <Section2/>
                
          <Suspense fallback={<div/>}>
          <Section3/>
      </Suspense>
           
      <Suspense fallback={<div/>}>
          <Section4/>
      </Suspense>
               
      <Suspense fallback={<div/>}>
          <Section5/>
      </Suspense>
              
      <Suspense fallback={<div/>}>
          <Section6/>
      </Suspense>
        
    </Route>
    <Route exact path="/payment">
         
    <Suspense fallback={<div/>}>
          <Main/>
      </Suspense>
    </Route>
    <Route exact path="/mint">
         
    <Suspense fallback={<div/>}>
          <Minting/>
      </Suspense>
    </Route>
    <Route exact path="/success">
          
    <Suspense fallback={<div/>}>
          <Success/>
      </Suspense>
    </Route>

       </Switch>
    </BrowserRouter>
   


    </div>
  );
}

export default App;
